<template>
  <div class="orderDetail">
    <b-modal id="modal-response" v-model="modalVisible" hide-footer hide-body centered class="p-3">
      <div slot="modal-header" class="d-flex align-items-center justify-content-between p-2 w-100">
        <span class="h4 mb-0 text-primary">Rincian Tagihan</span>
        <span @click="modalVisible = false" class="h4 mb-0 text-primary text-hover">x</span>
      </div>
      <div class="d-flex flex-column" v-for="({ allocated_amount, expired_at, order_number, outstanding_amount, total_order, status, paid_amount }, key) in paymentDetail && paymentDetail.payment_orders" :key="key">
        <div class="d-flex flex-column p-2">
          <div class="d-flex justify-content-between">
            <span class="h5">{{ order_number }}</span>
            <div class="d-flex">
              <span style="color: #767676">Batas waktu pelunasan</span>
              <span class="text-dark ml-1">{{ getExpireAt(expired_at) }}</span>
            </div>
          </div>
          <div class="d-flex justify-content-between">
            <div class="d-flex flex-column">
              <span class="h6 my-1" style="color: #767676">Tagihan</span>
              <span class="h6 my-1" style="color: #F5831F;">Rp. {{ toCurrency(total_order) }}</span>
              <div v-if="status === 'PAID'" class="d-flex align-items-center" style="color: #2CBD12">
                <a-icon type="check-circle" fill="green" />
                <span class="ml-1">Lunas</span>
              </div>
              <div v-else class="d-flex align-items-center">
                <span class="h6 my-1" style="color: #767676">Teralokasi</span>
                <span class="ml-1 text-dark">Rp. {{ toCurrency(allocated_amount) }}</span>
              </div>
            </div>
            <div class="d-flex align-items-end">
              <div style="border: solid 1px #015289; color: #015289"
                class="btn d-flex align-items-center justify-content-center rounded my-2 w-100">
                <span class="h6 mb-0">Lihat Detail</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <div class="d-flex justify-content-start align-items-center mb-3">
      <div>
        <h3> {{ $t('purchase.detail.title_detail') }}</h3>
      </div>
    </div>
    <a-row>
      <a-col :xs="24" :sm="24" :md="16" class="px-2 py-2">
        <a-row>
          <a-col :span="24" class="payment-information px-3 py-3">
            <a-row type="flex" wrap>
              <div class="d-flex align-items-center flex-column justify-content-between w-25"
                v-for="({ title, active, component, code }, key) in staticListOrderProcess" :key="key">
                <div>
                  <component v-bind:is="component" :key="key" :active="active"></component>
                </div>
                <div class="mt-2"
                  :class="active ? code.includes('CANCELED') ? 'text-cancel' : 'text-icon' : 'text-icon-pending'"> {{
                      title
                  }} </div>
              </div>
            </a-row>
          </a-col>
        </a-row>

        <a-row class="mt-4" v-if="paymentDetail && paymentDetail.status.toUpperCase() === 'PAID'">
          <a-col :span="24" class="payment-information px-3 py-3">
            <a-row type="flex" wrap>
              <div class="d-flex justify-content-between w-100 align-items-center" style="cursor: pointer" @click.prevent="() => openReceipt()">
                <div class="title-kwitansi">Lihat Laporan Pembayaran</div>
                <left-arrow-icon />
              </div>
            </a-row>
          </a-col>
        </a-row>

        <a-row class="mt-4">
          <a-col :span="24" class="payment-information px-3 py-3">
            <a-row type="flex" wrap>
              <div class="d-flex align-items-center w-100">
                <div class="d-flex justify-content-between w-100 align-items-center flex-column">
                  <div class="d-flex justify-content-between w-100 align-items-center mb-4">
                    <span class="h4 mb-0">Detail informasi</span>
                    <button v-if="paymentDetail && paymentDetail.status === 'UNPAID'" @click="$router.push({ path: `/account/how-to-pay-bill/${$route.params.payment_id}` })" class="btn rounded" style="border: solid 1px #015289; color: #015289">Lihat cara bayar</button>
                  </div>
                  <div class="d-flex justify-content-between w-100 align-items-center mb-2"
                    v-for="({ value, key, important }) in detailInformation" :key="key">
                    <span style="color:#767676" class="mb-0">{{ key }}</span>
                    <span :style="{ color: important ? '#F5831F' : '#767676' }" class="mb-0">{{ value }}</span>
                  </div>
                </div>
              </div>
            </a-row>
          </a-col>
        </a-row>
        <a-row class="mt-4" v-if="paymentDetail && paymentDetail.method && paymentDetail.method.payment_type === 'BANK_TRANSFER'">
          <a-col :span="24" class="payment-information px-3 py-3">
            <a-row type="flex" wrap>
              <div class="d-flex align-items-center w-100">
                <div class="d-flex justify-content-between w-100 align-items-center flex-column">
                  <div class="d-flex justify-content-between w-100 align-items-center mb-3">
                    <span class="h4 mb-0">Unggah Bukti Pembayaran</span>
                  </div>
                  <template>
                    <div v-if="image_struck && image_struck.url" class="d-flex flex-column justify-content-center">
                      <template v-if="loading">
                        <div class="d-flex flex-column align-items-center py-4">
                          <div><BigLoader class="rotate-img"/></div>
                          <div class="upload-text mt-2">
                            {{ $t('form.onUpload') }}
                          </div>
                        </div>
                      </template>
                      <template v-else>
                        <img :src="image_struck.url"
                          style="width: 100%; max-width: 100%; height: auto; border-radius: 13px; cursor: pointer;"
                          :alt="image_struck.url" @click.prevent="previewImage = true" />
                        <a-modal :visible="previewImage" :footer="null" @cancel="previewImage = false" centered>
                          <img :alt="image_struck.url" style="width: 100%" :src="image_struck.url" />
                        </a-modal>
                        <div>
                          <a-upload v-if="paymentDetail && paymentDetail.status !== 'UNPAID'" name="file"
                            class="w-100 d-flex justify-content-center mt-2 wrapped-upload" :multiple="false" @change="addImage"
                            :show-upload-list="false" :beforeUpload="() => false">
                            <a-button class="upload-change mb-3" style="width: 100%">
                              {{ $t('upload.changeImage') }}
                            </a-button>
                          </a-upload>
                        </div>
                      </template>
                    </div>
                    <div class="image-upload-container" v-else>
                      <a-upload-dragger :disabled="loading" name="image" :multiple="false" :show-upload-list="false" list-type="picture-card"
                        class="image-uploader" :beforeUpload="() => false" @change="addImage">
                        <div class="d-flex mb-2 flex-column w-100">
                          <div v-if="loading" class="">
                            <BigLoader class="rotate-img"/>
                            <div class="upload-text mt-2">
                              {{ $t('form.onUpload') }}
                            </div>
                          </div>
                          <div v-else>
                            <UploadButton />
                            <div class="upload-text">
                              {{ $t('form.pickPhoto') }}
                            </div>
                          </div>
                        </div>
                      </a-upload-dragger>
                      <div class="disclaimer-upload mt-1">Ukuran maksimal file 10MB dengan format jpg, png</div>
                    </div>
                  </template>
                </div>
              </div>
            </a-row>
          </a-col>
        </a-row>
      </a-col>
      <a-col :span="24" class="px-2 py-2 mb-3 ml-4 position-sticky" :xs="24" :sm="24" :md="7">
        <a-row>
          <a-col :span="24" class="payment-information px-3 py-3">
            <a-row type="flex" wrap>
              <div style="border: solid 1px #F5831F; color: #F5831F; background-color: #FFF2E6;"
                @click="modalVisible = true"
                class="btn d-flex align-items-center rounded my-2 w-100 p-3 d-flex justify-content-between">
                <span class="h6 mb-0">Rincian Tagihan ({{ paymentDetail && paymentDetail.payment_orders.length }})</span>
                <span class="h6 mb-0"><i class="fe fe-chevron-right font-size-18" /></span>
              </div>
              <div style="border: solid 1px #015289; color: #015289"
                class="btn d-flex align-items-center rounded my-2 w-100 p-3" @click.prevent="$router.go(-1)">
                <span class="h6 mb-0">Kembali</span>
              </div>
            </a-row>
          </a-col>
        </a-row>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import Vue from 'vue'
import OrderIcon from '@/components/Icons/Order.vue'
import OrderCancelIcon from '@/components/Icons/OrderCancel.vue'
import PaidWallet from '@/components/Icons/PaidWallet.vue'
import UploadButton from '@/components/Icons/UploadButton.vue'
import moment from 'moment'
import LeftArrowIcon from '@/components/Icons/LeftArrow.vue'
import BigLoader from '@/components/Icons/BigLoader.vue'

const staticListOrderProcess = [
  {
    title: 'Menunggu Konfirmasi',
    code: ['UNPAID'],
    active: true,
    component: Vue.extend(OrderIcon),
  },
  {
    title: 'Sudah Dibayar',
    code: ['PAID', 'PARTIAL_PAID'],
    active: false,
    component: Vue.extend(PaidWallet),
  },
]
const staticListOrderCancel = [
  {
    title: 'Menunggu Konfirmasi',
    code: ['UNPAID'],
    active: false,
    component: Vue.extend(OrderIcon),
  },
  {
    title: 'Dibatalkan',
    code: ['CANCELLED'],
    active: false,
    component: Vue.extend(OrderCancelIcon),
  },
]
export default {
  name: 'detail-bill-transaction',
  components: {
    UploadButton,
    LeftArrowIcon,
    BigLoader,
  },
  data() {
    return {
      staticListOrderProcess: [],
      detailInformation: null,
      modalVisible: false,
      paymentDetail: null,
      data: null,
      previewImage: false,
      loading: false,
    }
  },
  methods: {
    getOrderDetail() {
      this.$store.dispatch('billing/GETDETAILSETTLEMENT', {
        payment_id: this.$route.params.payment_id,
        channel_id: this.$store.state.app.channel_id,
      })
        .then(({ data }) => {
          const splitDate = data.expired_at.split(' ')
          const endDate = {
            day: moment(splitDate[0]).format('dddd'),
            date: moment(splitDate[0]).format('DD MMMM YYYY'),
            hour: moment(splitDate[1], 'HH:mm:ss').format('HH:mm'),
            countDown: [splitDate[0], splitDate[1]].join(' '),
          }
          const expiredDate = moment(endDate.countDown).format('YYYY-MM-DD HH:mm:ss')
          const hours = this.getDifferenceInHours(new Date(expiredDate).getTime(), new Date().getTime())
          this.countDown = hours
          const newInstructions = (data && data.instructions) || (data && data.instructions && data.instructions.instructions) || {}
          this.data = {
            ...data,
            newInstructions,
          }
        })
        .catch(err => {
          console.log(err)
        })
      this.$store.dispatch('billing/GETDETAILSETTLEMENTPAYMENTHISTORY', {
        payment_id: this.$route.params.payment_id,
        channel_id: this.$store.state.app.channel_id,
      })
        .then(({ data }) => {
          this.paymentDetail = data ?? null
          let paymentType = ''
          let isActive = true
          const tempStaticListOrderProcess = []
          switch (data?.method?.payment_type) {
            case 'VA':
              paymentType = 'Virtual Account'
              break
            case 'BANK_TRANSFER':
              paymentType = 'Bank Transfer'
              this.getProofPayment()
              break
            case 'EWALLET':
              paymentType = 'E-Wallet'
              break
            case 'DEPOSIT_RETURN':
              paymentType = 'Deposit Return'
              break
            default:
              paymentType = '-'
              break
          }
          (data && data.status.toUpperCase() === 'CANCELLED' ? staticListOrderCancel : staticListOrderProcess).map((obj) => {
            if (obj.code.includes(data?.status)) {
              tempStaticListOrderProcess.push({
                ...obj,
                active: isActive,
              })
              isActive = false
            } else {
              tempStaticListOrderProcess.push({
                ...obj,
                active: isActive,
              })
            }
          })
          this.staticListOrderProcess = tempStaticListOrderProcess
          const createArrDetailInformation = [
            {
              key: 'Nomor Transaksi',
              value: data?.payment_number,
            },
            {
              key: 'Distributor',
              value: '-',
            },
            {
              key: 'Total Tagihan',
              value: Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR' }).format(data?.total_amount ?? 0),
            },
            {
              key: 'Total Yang Dibayarkan',
              value: Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR' }).format(data?.paid_amount ?? 0),
              important: true,
            },
            {
              key: 'Metode Pembayaran',
              value: paymentType,
            },
            {
              key: data?.method?.payment_type.toUpperCase() !== 'EWALLET' ? 'Bank' : 'E-Wallet',
              value: data?.method?.payment_type.toUpperCase() !== 'EWALLET' ? data?.method?.bank_name : data?.method?.payment_name,
              hide: Boolean(data?.method?.payment_type.toUpperCase() === 'DEPOSIT_RETURN'),
            },
            {
              key: 'Tanggal Pembayaran',
              value: moment(data?.created_at).format('DD MMM YYYY'),
            },
          ]
          this.detailInformation = createArrDetailInformation.filter((obj) => !obj.hide)
          const sellerIdDistri = data?.seller_id
          this.$store.dispatch('warehouse/GETWAREHOUSE_SELLER', {
            channel_id: this.$store.state.user.user_data.channel_id,
            seller_id: [sellerIdDistri],
          })
            .then(({ data }) => {
              this.$store.dispatch('warehouse/GETWAREHOUSELIST', {
                id: data.map((item) => item.warehouse_id),
              })
                .then(({ data }) => {
                  const sellerIdDetail = data.find((obj) => obj.seller_id === sellerIdDistri)
                  const findIndex = this.detailInformation.findIndex((obj) => obj.key.toLowerCase() === 'distributor')
                  if (findIndex >= 0) {
                    this.detailInformation[findIndex].value = sellerIdDetail?.name ?? '-'
                  }
                })
            })
        })
        .catch(err => {
          console.log(err)
        })
    },
    getProofPayment() {
      this.loading = true
      this.$store.dispatch('purchase/GETPROOFBYPAYMENTID', {
        payment_id: this.$route.params.payment_id,
        channel_id: this.$store.state.app.channel_id,
      })
        .then(({ data }) => {
          this.loading = false
          if (data) {
            this.image_struck = {
              file: '',
              url: data.image_url,
              fileList: [],
            }
          }
        })
        .catch(err => {
          this.loading = false
          console.log(err)
        })
    },
    toCurrency(value) {
      return `${Intl.NumberFormat('en-US').format(value || 0)}`
    },
    getExpireAt(date) {
      return date ? moment(date).format('DD MMM YYYY') : '-'
    },
    openReceipt() {
      const route = this.$router.resolve({ path: `/account/bill/receipt/${this.$route.params.payment_id}` })
      window.open(route.href, '_blank')
    },
    addImage({ file }) {
      this.loading = true
      const types = ['image/png', 'image/jpg', 'image/jpeg']
      const seller_id = this.$store.state.user.user_data.seller[0].id
      const account_number = this.paymentDetail ? this.paymentDetail.account_number : 0
      const seller_payment_method_id = this.paymentDetail ? this.paymentDetail.method.id : 0
      const bank_name = this.paymentDetail ? this.paymentDetail.method?.bank_name : ''
      const cad_settlement = true
      const payment_id = this.paymentDetail ? this.paymentDetail.payment_id : ''
      if (!types.includes(file.type)) {
        this.loading = false
        return this.$notification.error({
          message: this.$t('authForm.failUploadImage'),
          description: 'Invalid format file',
        })
      }
      if (file.size > 10000000) {
        return this.$notification.error({
          message: this.$t('authForm.failUploadImage'),
          description: 'Image Size Max 10 MB',
        })
      }
      this.$store.dispatch('purchase/GETDETAILBANKACCOUNT', {
        account_number,
        channel_id: this.$store.state.app.channel_id,
      })
        .then(({ data }) => {
          return this.$store.dispatch('purchase/UPLOADPROOFPAYMENT', {
            channel_id: this.$store.state.app.channel_id,
            seller_id,
            account_id: data ? data.id : '',
            account_number,
            seller_payment_method_id,
            bank_name,
            cad_settlement,
            payment_id,
            image: file,
          }).then((res) => {
            this.$notification.success({
              message: res.message,
            })
            setTimeout(() => {
              this.$router.go(0)
            }, 1000)
          }).catch((err) => {
            console.log({ err })
            this.loading = false
            this.$notification.error({
              message: err.response.data.message,
            })
          })
        })
        .catch((err) => {
          console.log({ err })
          this.loading = false
          this.$notification.error({
            message: err,
          })
        })
    },
  },
  mounted() {
    this.getOrderDetail()
  },
}
</script>

<style lang="scss">
.orderDetail {
  color: black;

  &-icon {
    background: #fff !important;
    padding: 6px 11px;
    border-radius: 50%;
  }

  .payment-information {
    background: #FFFFFF;
    box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.15);
    border-radius: 10px;

    .confirm-payment {
      height: 33px;
      background: #FFFFFF;
      border: 1px solid #265c9b !important;
      border-radius: 5px;
      color: #265C9B;
      cursor: pointer;
    }

    .status-icon-done {
      background: #015CA1;
      width: 60px;
      height: 60px;
      border-radius: 100px;
    }

    .status-icon-done-mobile {
      background: #015CA1;
      width: 40px;
      height: 40px;
      border-radius: 100px;
    }

    .status-icon-pending {
      background: #D2D2D2;
      border-radius: 100px;
      width: 60px;
      height: 60px;
    }

    .status-icon-pending-mobile {
      background: #D2D2D2;
      border-radius: 100px;
      width: 40px;
      height: 40px;
    }

    .text-icon {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      text-align: center;
      color: #015CA1;
    }

    .text-icon-mobile {
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 22px;
      text-align: center;
      color: #015CA1;
    }

    .text-icon-pending {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      text-align: center;
      color: #767676;
    }

    .text-cancel {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      text-align: center;
      color: #FF0000;
    }

    .title {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0.05em;
      color: #000000;
    }

    .title-2 {
      font-family: Roboto;
      font-style: normal;
      font-weight: light;
      font-size: 14px;
      line-height: 16px;
      color: #2B2B2B;
    }

    .total-payment {
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 21px;
      color: #F5831F;
    }

    .status-payment-paid {
      background: #265C9B;
      color: #FFFFFF;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 5px 15px;
      width: 25%;
      border-radius: 16px;
      font-size: 14px;
    }

    .status-payment-unpaid {
      font-size: 14px;
      background: #F5831F;
      color: #FFFFFF;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 5px 15px;
      width: 25%;
      border-radius: 16px;
    }

    .status-payment-cancel {
      font-size: 14px;
      background: #FF0000;
      color: #FFFFFF;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 5px 15px;
      width: 25%;
      border-radius: 16px;
    }
  }

  .hover:hover {
    cursor: pointer;
    width: fit-content;
  }

  .payment-method {
    background: #FFFFFF;
    box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.15);
    border-radius: 10px;

    .status-payment2 {
      color: #F5831F;
    }

    .margin-width-copy {
      margin-left: auto;
      flex-direction: row;
    }

    .copy-text {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      color: #015CA1;
      text-align: center;
    }

    .title {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0.05em;
      color: #000000;
    }

    .title-2 {
      font-style: normal;
      font-weight: light;
      font-size: 14px;
      line-height: 25px;
      color: #000000;
    }
  }

  .shipping-information {
    background: #FFFFFF;
    box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.15);
    border-radius: 10px;

    .title {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0.05em;
      color: #000000;
    }

    .content-title {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      color: #585858;
    }

    .content-value {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: #000000;
    }
  }

  .product-information {
    background: #FFFFFF;
    box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.15);
    border-radius: 10px;

    .title {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0.05em;
      color: #000000;
    }

    img {
      width: 97px;
      height: 93.62px;
    }

    .product-title {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      color: #000000;
    }

    .product-sku {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: #8C8C8C;
    }

    .price-1 {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: #F5831F;
    }

    .price-2 {
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 23px;
      color: #000000;
    }
  }
}

.checkout-calculation {
  background: #FFFFFF;
  border-radius: 10px;

  .title {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.05em;
    color: #000000;
  }

  .point {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #F5831F;
  }

  .total {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    color: #F5831F;
  }

  .term-payment {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    text-align: right;
    color: #000000;
  }

  .confirm-payment {
    height: 33px;
    background: #265C9B;
    border-radius: 5px;
    color: #FFFFFF;
    cursor: pointer;
  }
}

.wrapper-upload {
  background: #FAFAFA;
  border: none;
}

.wrapper-upload:hover {
  background: #FAFAFA;
  border: none;
}

.image-uploader {
  .ant-upload-select-picture-card {
    margin: 0 !important;
    width: 140px !important;
    height: 140px !important;

    img {
      max-width: 100%
    }

    i {
      font-size: 32px;
      color: #999;
    }

    .ant-upload-text {
      margin-top: 8px;
      color: #666;
    }
  }
}

.upload-change {
  cursor: pointer;
  background: #FFFFFF;
  border: 1px solid #265c9b !important;
  border-radius: 5px;
  color: #265C9B;
  width: 100%;
}

.wrapped-upload>div {
  width: 100% !important;
}

.btn-back {
  background: #FFFFFF;
  border: 1px solid #265c9b !important;
  border-radius: 5px;
  color: #265C9B;
  cursor: pointer;
  width: 100%;
}

.warehouse-name {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.05em;
  color: #015ca1;
}

.action-button {
  border-radius: 8px;
}

.payment-type-label {
  color: #F5831F;
}

.section-bill-payment {
  color: #265C9B;
  background: #f4f4f4;
  padding: 15px 20px;
  margin-top: 20px;
  border-radius: 15px;

  span {
    font-weight: bold;
  }
}

.title-kwitansi {
  font-family: Open Sans;
  font-size: 18px;
  font-weight: 600;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
  color: #015CA1;
}
.upload-text {
  font-family: Roboto;
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0em;
  text-align: center;
  color: #9B9B9B;
}
.image-upload-container {
  align-self: flex-start;
  width: 300px;
}
.disclaimer-upload {
  font-family: Open Sans;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
  color: #767676;
}
.rotate-img {
  animation: rotation 1s infinite linear;
  height: 55px;
  width: auto
}
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
</style>
