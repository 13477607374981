<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="57" height="57" viewBox="0 0 57 57" fill="none">
    <circle v-if="active" cx="28.5" cy="28.5" r="28.5" fill="#015CA1" />
    <circle v-else cx="28.5" cy="28.5" r="28.5" fill="#D2D2D2" />
    <path
      d="M41.9685 42.6654H17.331C14.8057 42.6654 12.75 40.6097 12.75 38.0843V24.3779C12.75 21.8525 14.8057 19.7969 17.331 19.7969H41.9685C44.4938 19.7969 46.5495 21.8525 46.5495 24.3779V28.0073C46.5495 28.5868 46.0798 29.0626 45.5003 29.0626C44.9208 29.0626 44.4511 28.5929 44.4511 28.0073V24.3779C44.4511 23.0115 43.3409 21.9013 41.9746 21.9013H17.3371C15.9707 21.9013 14.8606 23.0115 14.8606 24.3779V38.0843C14.8606 39.4507 15.9707 40.5609 17.3371 40.5609H41.9746C43.3409 40.5609 44.4511 39.4507 44.4511 38.0843V34.4549C44.4511 33.8754 44.9208 33.3996 45.5003 33.3996C46.0798 33.3996 46.5495 33.8693 46.5495 34.4549V38.0843C46.5495 40.6097 44.4938 42.6654 41.9685 42.6654Z"
      :fill="active ? 'white' : 'black'" />
    <path
      d="M47.5081 35.513H39.987C37.6263 35.513 35.7109 33.5976 35.7109 31.237C35.7109 28.8763 37.6263 26.9609 39.987 26.9609H47.5081C48.0876 26.9609 48.5573 27.4306 48.5573 28.0162V34.4577C48.5634 35.0433 48.0876 35.513 47.5081 35.513ZM39.987 29.0654C38.7914 29.0654 37.8154 30.0414 37.8154 31.237C37.8154 32.4325 38.7914 33.4085 39.987 33.4085H46.4589V29.0654H39.987Z"
      :fill="active ? 'white' : 'black'" />
    <path
      d="M38.5981 21.6218C38.3602 21.6218 38.1223 21.5303 37.9455 21.3473C37.5917 20.9874 37.5978 20.4079 37.9577 20.0541L40.0133 18.0351L30.65 10.1662L20.4632 21.0118C20.1155 21.3778 19.536 21.3961 19.17 21.0545C18.804 20.7068 18.7857 20.1273 19.1273 19.7613L29.9119 8.28745C30.2413 7.93365 30.7964 7.90315 31.1685 8.21425L41.9592 17.2848C42.1544 17.4495 42.2764 17.6935 42.2825 17.9497C42.2947 18.2059 42.191 18.456 42.008 18.639L39.2386 21.3656C39.0617 21.5303 38.8299 21.6218 38.5981 21.6218Z"
      :fill="active ? 'white' : 'black'" />
    <path
      d="M18.1159 28.6083C19.3541 27.8763 20.9462 28.4558 21.422 29.81C21.6965 30.5968 22.3797 31.1641 23.2032 31.3044C24.6183 31.5362 25.4723 33.0124 24.966 34.3544C24.6732 35.1352 24.8257 36.0074 25.3686 36.6418C26.3019 37.7337 26.0091 39.4051 24.7586 40.1066C24.0328 40.5153 23.5875 41.2838 23.5997 42.1134C23.618 43.5469 22.3126 44.6449 20.9035 44.3765C20.0861 44.224 19.2505 44.529 18.7198 45.1695C17.8109 46.2796 16.109 46.2796 15.2001 45.1695C14.6694 44.529 13.8338 44.224 13.0164 44.3765C11.6073 44.6449 10.3019 43.5469 10.3202 42.1134C10.3324 41.2838 9.88713 40.5092 9.16124 40.1066C7.91077 39.4051 7.61187 37.7276 8.55125 36.6418C9.09414 36.0135 9.24663 35.1352 8.95384 34.3544C8.44755 33.0124 9.29543 31.5362 10.7167 31.3044C11.5402 31.1702 12.2173 30.5968 12.4979 29.81C12.9737 28.4558 14.5718 27.8763 15.804 28.6083C16.5116 29.0292 17.3961 29.0292 18.1159 28.6083Z"
      fill="url(#paint0_linear_7690_106298)" />
    <path
      d="M15.1019 41.522C14.9433 41.522 14.7908 41.461 14.6688 41.3451L10.8503 37.5327C10.6124 37.2948 10.6124 36.9105 10.8503 36.6726C11.0882 36.4347 11.4725 36.4347 11.7165 36.6726L15.1446 40.0946L21.8362 34.623C22.0985 34.4095 22.4828 34.4461 22.6963 34.7084C22.9098 34.9707 22.8732 35.355 22.6109 35.5685L15.4923 41.3878C15.3764 41.4793 15.2422 41.522 15.1019 41.522Z"
      :fill="active ? 'white' : 'black'" />
    <path
      d="M41.3725 25.5325H18.5162C18.1807 25.5325 17.9062 25.258 17.9062 24.9225C17.9062 24.587 18.1807 24.3125 18.5162 24.3125H41.3725C41.708 24.3125 41.9825 24.587 41.9825 24.9225C41.9825 25.258 41.708 25.5325 41.3725 25.5325Z"
      :fill="active ? 'white' : 'black'" />
    <defs>
      <linearGradient id="paint0_linear_7690_106298" x1="7.99397" y1="37.1465" x2="25.913" y2="37.1465"
        gradientUnits="userSpaceOnUse">
        <stop stop-color="#F26522" />
        <stop offset="0.2497" stop-color="#F47D23" />
        <stop offset="1" stop-color="#F47920" />
      </linearGradient>
    </defs>
  </svg>
</template>
<script>
export default {
  props: ['active'],
}
</script>
