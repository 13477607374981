<template>
    <svg width="13" height="19" viewBox="0 0 13 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.4788 8.36153L11.4789 8.36161C11.8276 8.65984 12.0208 9.0704 12.0208 9.49998C12.0208 9.92918 11.828 10.3398 11.4792 10.6383L11.4791 10.6384L3.936 17.0879C3.93596 17.0879 3.93593 17.088 3.9359 17.088C3.26124 17.6651 2.19611 17.6652 1.52151 17.088L11.4788 8.36153ZM11.4788 8.36153L3.93567 1.91185M11.4788 8.36153L3.93567 1.91185M7.73289 9.49998L1.52127 4.18909L1.52116 4.18899C0.798585 3.57086 0.79841 2.53 1.52118 1.91192L7.73289 9.49998ZM7.73289 9.49998L1.52149 14.8109L1.52141 14.811C0.798849 15.429 0.798637 16.4698 1.52132 17.0878L7.73289 9.49998ZM3.93567 1.91185C3.26101 1.33499 2.19586 1.33488 1.52126 1.91186L3.93567 1.91185Z" fill="#015CA1" stroke="#015CA1" stroke-width="1.04167"/>
    </svg>
</template>

<script>
export default {
  name: 'LeftArrow',
  props: {
    rotate: String,
  },
}
</script>
